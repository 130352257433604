import { Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "@emotion/styled";
import { teal } from "@mui/material/colors";
import { getCntbkTimestamp } from "../commons";
import { SendedJudg } from "./common/SendedJusge";
import FapSnackMsg from "./common/FapSnackMsg";
import { endPoint, makeUrlSearchParams, useFetchAlbDt } from "../customHooks/ApiClient";

const TIME_LIMIT = 10; //メール返信の期限（時間で指定）;
const DAY_LIST = ["日", "月", "火", "水", "木", "金", "土" ];

const UrlError = () => {
  return(
    <div style={{textAlign: 'center'}}>
      <p>無効なURLです。</p>
    </div>
  )
}

const TextContainer = styled('div')({
  whiteSpace: 'pre-wrap', lineHeight: '1.5rem', padding: '0 16px'
});
const ExplanationText = ({dateObj, timeOver}) => {
  const newDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  const month = String(newDate.getMonth()+1).padStart(2, "0");
  const date = String(newDate.getDate()).padStart(2, "0");
  const text = (() => {
    if(timeOver){
      return `${month}月${date}日10時を過ぎたため、メッセージの送信はできません。`
    }

    return `メッセージは${month}月${date}日10時まで送信することができます。`
  })().replaceAll(" ", "");

  return(
    <TextContainer>{text}</TextContainer>
  )
}

const Headline = styled('div')({
  fontSize: 10, color: teal[800], fontWeight: 'bold'
  // borderBottom: `1px solid ${teal[600]}`,
});

const JigyosyoMessageMain = styled('div')({
  margin: '32px 0', padding: '0 16px',
  '& .content': {
    lineHeight: '1.5rem', whiteSpace: 'pre-wrap',
  }
});

const JigyosyoMessage = (props) => {
  const {contentDt} = props;

  return(
    <JigyosyoMessageMain>
      <Headline>事業所からのメッセージ</Headline>
      <div className="content">{contentDt.content.replaceAll("<br>", "\n")}</div>
    </JigyosyoMessageMain>
  )
}

const FamilyInputTextFormMain = styled('div')({
  padding: '0 16px',
  '& .textForm': {width: '100%'}
});
const MyTextField = styled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: teal[600],
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: teal[600],
    },
  }
});
const ButtonWrapper = styled('div')({
  margin: '8px 0', textAlign: 'end'
});
const SendButton = styled(Button)({
  backgroundColor: teal[800], fontWeight: 'bold',
  '&:hover': {backgroundColor: teal[900],},
});
const CanselButton = styled(Button)({
  fontWeight: 'bold'
});

const convertBrtoLf = (str) => str.replaceAll("<br>", "\n");

const exceptionVitalForm = (text, dtName, kind, vitalDt) => {
  if(kind || kind===0){
    if(dtName === "bloods"){
      const origin = vitalDt[dtName] ?[...vitalDt[dtName]] :["", "", ""];
      origin[kind] = text;
      return origin;
    }else if(dtName === "sleep" && kind !== "sleeptime"){
      const origin = vitalDt[dtName] ?vitalDt.sleep.includes("-") ?vitalDt.sleep.split("-") :null :null;
      if(origin && kind === "bedtime"){
        return `${text}-${origin[1]}`;
      }else if(origin && kind === "wakeuptime"){
        return `${origin[0]}-${text}`;
      }else if(!origin && kind === "bedtime"){
        return `${text}-`;
      }else if(!origin && kind === "wakeuptime"){
        return `-${text}`;
      }
    }
  }
  return text;
}

const AlbHMuiTextField = (props) => {
  // const classes = useStyles();
  const {
    id, label, placeholder, name, value, setValue,
    InputLabelProps, InputProps,
    error, helperText, disabled,
    color, variant, style, width=null, 
    multiline, maxRows, minRows,
    onChange, onBlur, onFocus,
    autoCompleteParams,
  } = props;

  const handleChange = (e) => {
    if(setValue) setValue(e.target.value);
    if(onChange) onChange(e);
  }

  const handleBlur = (e) => {
    if(onBlur) onBlur(e); 
  }

  const handleFocus = (e) => {
    // if(parseInt(getUisCookie(uisCookiePos.selectInputAuto))){
    //   e.target.select();
    // }
    if(onFocus) onFocus(e);
  }

  const params = {
    id, label, placeholder, name, value,
    InputLabelProps, InputProps,
    error, helperText, disabled,
    color, variant, 
    multiline, maxRows, minRows,
    ...autoCompleteParams,
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
  };

  return(
    <TextField
      {...params}
      style={{width, ...style}}
      // className={classes.textField}
    />
  )
}

/// 半角全角変換
export const convHankaku = (strVal, numMode = false) => {
  if (strVal === undefined) return ('');
  if (numMode)  strVal = strVal.replace(/^−/, '-');  
  strVal = strVal.trim();
  // 半角変換
  var halfVal = strVal.replace(/[！-～]/g,
    function (tmpStr) {
      // 文字コードをシフト
      return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
    }
  );

  // 文字コードシフトで対応できない文字の変換
  return halfVal.replace(/”/g, "\"")
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    // 数字に続く音引きはハイフンに変換
    // convHankaku('なんとかタワー１２３ー４５')
    // "なんとかタワー123-45"
    .replace(/([0-9]+)ー/g, "$1-")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
}

const VitalFormTextField = (props) => {
  const {
    dtName, kind="", vitalDt, setVitalDt, val, label, placeholder, adronment="", width,
    varidate=null, disabledDt, setDisabledDt, errMsg="", canselButtonDisabled, setCanselButtonDisabled
  } = props;
  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    if(canselButtonDisabled) setInputError(false);
  }, [canselButtonDisabled])
  const handleChange = (e) => {
    const text = e.target.value;
    const data = {...vitalDt};
    data[dtName] = exceptionVitalForm(text, dtName, kind, vitalDt);
    setVitalDt({...data});
    if(varidate && !new RegExp(varidate).test(text)){
      setInputError(true);
      setDisabledDt({...disabledDt, [dtName+kind]: true});
    }else{
      setDisabledDt({...disabledDt, [dtName+kind]: false});
      setInputError(false);
    }
    setCanselButtonDisabled(false);
  }
  const handleBlur = (e) => {
    const han_text = convHankaku(e.target.value);
    const data = {...vitalDt};
    data[dtName] = exceptionVitalForm(han_text, dtName, kind, vitalDt);
    setVitalDt({...data})
  }

  return(
    <AlbHMuiTextField
      error={inputError}
      label={label}
      placeholder={placeholder}
      value={val}
      variant={'standard'}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        // endAdornment: <InputAdornment position="end">{adronment}</InputAdornment>,
      }}
      helperText={inputError ?errMsg :""}
      style={{margin: "8px 8px 8px 4px", width}}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

const VitalForm = (props) => {
  const {contactDt, vitalDt, setVitalDt, disabledDt, setDisabledDt, canselButtonDisabled, setCanselButtonDisabled} = props;
  const contactDtEtc = contactDt.etc ?contactDt.etc :{};
  if(!contactDtEtc) return null;
  const vitalRequired = contactDtEtc.vitalRequired ?contactDtEtc.vitalRequired[1] :false;
  if(!vitalRequired) return null;
  const vital = contactDtEtc ?contactDtEtc.vital ?contactDtEtc.vital :null :null;
  if(!vital) return null;
  const temperature_val = vitalDt.temperature ?vitalDt.temperature :"";
  const maxBloodPressure_val = vitalDt.bloods ?vitalDt.bloods[0] :"";
  const minBloodPressure_val = vitalDt.bloods ?vitalDt.bloods[1] :"";
  const bloodPulse_val = vitalDt.bloods ?vitalDt.bloods[2] :"";
  const excretion_val = vitalDt.excretion ?vitalDt.excretion :"";
  const spo2_val = vitalDt.spo2 ?vitalDt.spo2 :"";
  const sleeptime_val = vitalDt.sleep ?!vitalDt.sleep.includes("-") ?vitalDt.sleep :"" :"";
  const bedtime_val = vitalDt.sleep ?vitalDt.sleep.includes("-") ?vitalDt.sleep.split("-")[0] :"" :"";
  const wakeuptime_val = vitalDt.sleep ?vitalDt.sleep.includes("-") ?vitalDt.sleep.split("-")[1] :"" :"";
  const temperature = {
    dtName: 'temperature', val: temperature_val, label: "体温", placeholder: "例：36.5", adronment: "度", width: '8rem',
    varidate: "(^[1-9１-９][0-9０-９]\\.[0-9０-９]+$)|(^[1-9１-９][0-9０-９]$)", errMsg: "数字または小数点のみ"
  }
  const maxBloodPressure = {
    dtName: "bloods", kind: 0, val: maxBloodPressure_val, label: "最高血圧", placeholder: "例：120", adronment: "mmHg", width: '8rem',
    varidate: "^[1-9１-９][0-9０-９]{0,2}$", errMsg: "数字のみ"
  }
  const minBloodPressure = {
    dtName: "bloods", kind: 1, val: minBloodPressure_val, label: "最低血圧", placeholder: "例：80", adronment: "mmHg", width: '8rem',
    varidate: "^[1-9１-９][0-9０-９]{0,2}$", errMsg: "数字のみ"
  }
  const bloodPulse = {
    dtName: "bloods", kind: 2, val: bloodPulse_val, label: "脈拍", placeholder: "例：90", adronment: "回/分", width: '8rem',
    varidate: "^[1-9１-９][0-9０-９]{0,2}$", errMsg: "数字のみ"

  }
  const excretion = {
    dtName: "excretion", val: excretion_val, label: "排泄", placeholder: "例：大○回、小○回", width: '10rem',

  }
  const spo2 = {
    dtName: "spo2", val: spo2_val, label: "血中酸素濃度", placeholder: "例：96", adronment: "%", width: '8rem',
    varidate: "(^[1-9１-９][0-9０-９]{0,1}\\.[0-9０-９]+$)|(^[1-9１-９][0-9０-９]{0,2}$)", errMsg: "数字または小数点のみ"
  }
  const sleeptime = {
    dtName: "sleep", kind: "sleeptime", val: sleeptime_val, label: "睡眠時間", placeholder: "例：8.5", width: '8rem', adronment: "時間",
    varidate: "(^[1-9１-９][0-9０-９]{0,1}\\.[0-9０-９]+$)|(^[1-9１-９][0-9０-９]{0,2}$)", errMsg: "数字または小数点のみ"
  }
  const bedtime = {
    dtName: "sleep", kind: "bedtime", val: bedtime_val, label: "就寝時間", placeholder: "例：22:00", width: '8rem',
    varidate: "^[1-2１-２]{0,1}[0-9０-９]:[0-5０-５][0-9０-９]$", errMsg: ":で分けた数字のみ"
  }
  const wakeuptime = {
    dtName: "sleep", kind: "wakeuptime", val: wakeuptime_val, label: "起床時間", placeholder: "例：7:00", width: '8rem',
    varidate: "^[1-2１-２]{0,1}[0-9０-９]:[0-5０-５][0-9０-９]$", errMsg: ":で分けた数字のみ"
  }
  const common_parms = {vitalDt, setVitalDt, disabledDt, setDisabledDt, canselButtonDisabled, setCanselButtonDisabled};
  return(
    <div>
      {vital.temperature ?<div>
        <VitalFormTextField {...{...temperature, ...common_parms}}/>
      </div> :null}
      {vital.bloods ?<div>
        <VitalFormTextField {...{...maxBloodPressure, ...common_parms}}/>
        <VitalFormTextField {...{...minBloodPressure, ...common_parms}}/>
        <VitalFormTextField {...{...bloodPulse, ...common_parms}}/>
      </div> :null}
      {vital.excretion ?<div>
        <VitalFormTextField {...{...excretion, ...common_parms}}/>
      </div> :null}
      {vital.spo2 ?<div>
        <VitalFormTextField {...{...spo2, ...common_parms}}/>
      </div> :null}
      {vital.sleep===1 ?<div>
        <VitalFormTextField {...{...sleeptime, ...common_parms}}/>
      </div> :null}
      {vital.sleep===2 ?<div>
        <VitalFormTextField {...{...bedtime, ...common_parms}}/>
        <VitalFormTextField {...{...wakeuptime, ...common_parms}}/>
      </div> :null}
    </div>
  )
}

const FamilyInputTextForm = ({contactDt, setContactDt, dDate, params, setSnack}) => {
  const {jino, mail, token, ctoken, uid, stdDate} = params;
  let timestamp = null, content="", thisVitalDt = {};
  if(contactDt[dDate] && contactDt[dDate][1]){
    const thisContactDt = contactDt[dDate][1];
    timestamp = thisContactDt.datetime ?thisContactDt.datetime :null;
    content = thisContactDt.content ?thisContactDt.content :"";
    thisVitalDt = thisContactDt.vital ?thisContactDt.vital :{};
  }
  const [text, setText] = useState(convertBrtoLf(content));
  const [vitalDt, setVitalDt] = useState({...thisVitalDt});
  const [disabledDt, setDisabledDt] = useState({});
  const [canselButtonDisabled, setCanselButtonDisabled] = useState(true);

  const handleClick = () => {
    const sendData = {...contactDt};
    if(!sendData[dDate][1]) sendData[dDate][1] = {};
    sendData[dDate][1].content = text.replaceAll("\n", "<br>");
    // sendData[dDate][1].datetime = getCntbkTimestamp(new Date());
    sendData[dDate][1].timestampSent = new Date().getTime();
    sendData[dDate][1].sent = true;
    let newVitalDt = {};
    for(let key in vitalDt){
      if(vitalDt[key]) newVitalDt[key] = vitalDt[key];
    }
    sendData[dDate][1].vital = newVitalDt;
    const apiParams = {
      a: "sendPartOfContactJino",
      jino, mail, faptaken: token, ctoken, date: stdDate, uid, 
      content: JSON.stringify(sendData)
    };
    axios.post(endPoint, makeUrlSearchParams(apiParams)).then(res => {
      if(res.data.result){
        setContactDt({...contactDt, ...sendData});
        setSnack({...{
          open: true, msg: 'メッセージを送信しました。', severity: 'success'
        }});
      }else{
        setSnack({...{
          open: true, msg: '送信失敗', severity: 'error'
        }});
      }
    });
  }

  const vitalProps = {contactDt, vitalDt, setVitalDt, disabledDt, setDisabledDt, canselButtonDisabled, setCanselButtonDisabled}

  const buttonDisabled = (() => (
    (convertBrtoLf(content) === text && JSON.stringify(vitalDt)===JSON.stringify(thisVitalDt)) ||
    Object.values(disabledDt).some(x => x)
  ))();
  return(
    <FamilyInputTextFormMain>
      <MyTextField
        id="outlined-multiline-static"
        label="メッセージを入力"
        multiline
        rows={4}
        className="textForm"
        value={text}
        onChange={e=>{
          setText(e.target.value);
          setCanselButtonDisabled(false);
        }}
      />
      <VitalForm {...vitalProps}/>
      <SendedJudg timestamp={timestamp} option={"familyMsg"} style={{justifyContent: 'flex-end'}}/>
      <ButtonWrapper>
        <CanselButton
          variant="contained"
          disabled={canselButtonDisabled}
          onClick={()=>{
            setText(convertBrtoLf(content));
            setCanselButtonDisabled(true);
            setVitalDt({...thisVitalDt});
          }}
        >
          キャンセル
        </CanselButton>
        <SendButton
          variant="contained"
          sx={{marginLeft: '8px'}}
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          送信
        </SendButton>
      </ButtonWrapper>
    </FamilyInputTextFormMain>
  )
}

const FamilyMessage = (props) => {
  const {contentDt} = props;

  return(
    <JigyosyoMessageMain>
      <Headline>返信した内容</Headline>
      <div className="content" style={{minHeight: '4.5rem'}}>{contentDt.content.replaceAll("<br>", "\n")}</div>
    </JigyosyoMessageMain>
  )
}

const MainForm = ({params, setSnack, contactDt, setContactDt}) => {
  const {jino, mail, token, ctoken, uid, stdDate, date} = params;
  // const albDtParams = {a: "fetchPartOfContactJino", jino, mail, faptaken: token, ctoken, uid, date: stdDate};
  // const [contactDt, setContactDt] = useFetchAlbDt({apiParams: albDtParams})

  const [stdYear, stdMonth] = stdDate.split("-").map(x => parseInt(x));
  const dateObj = new Date(stdYear, stdMonth-1, parseInt(date));
  const year = String(dateObj.getFullYear());
  const month = String(dateObj.getMonth()+1).padStart(2, '0');
  const day = DAY_LIST[dateObj.getDay()];
  const dDate = `D${year+month+date}`;
  if(contactDt === null){
    return(
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    )
  }
  const contents = contactDt[dDate];
  if(!contents){
    return(<UrlError />)
  }
  const jigyosyoMessageProps = {contentDt: contents[0]}
  const familyInputTextFormProps = {contactDt, setContactDt, dDate, params, setSnack};

  const ReplyForm = styled('div')({
    width: '100%', maxWidth: 640, margin: '0 auto',
    '& .date': {
      margin: 16, borderBottom: `1px solid ${teal[400]}`,
      '& .info': {fontSize: 12},
      '& .name': {fontSize: 24, margin: '0 4px'}
    }
  })

  const now = new Date();
  const timeLimit = new Date(stdYear, stdMonth-1, parseInt(date), 10);
  const timeOver = now.getTime() > timeLimit.getTime();

  const jidoName = contactDt.etc ?contactDt.etc.name :"";
  const vitalRequired = contactDt.etc ?contactDt.etc.vitalRequired ?contactDt.etc.vitalRequired[1] :false :false;

  return(
    <ReplyForm>
      <div className="date">
        <span className="info">{month}月{date}日{day}曜日</span>
        <span className="name">{jidoName}</span>
        <span className="info">さま</span>
      </div>
      <ExplanationText dateObj={dateObj} timeOver={timeOver}/>
      <JigyosyoMessage {...jigyosyoMessageProps}/>
      {timeOver
        ?<FamilyMessage contentDt={contents[1]}/>
        :<FamilyInputTextForm {...familyInputTextFormProps}/>
      }
    </ReplyForm>
  )
}

const Header = () => {
  const Title = styled('div')({
    fontSize: '1.5rem', fontWeight: 'bold', color: 'white',
    textAlign: 'center', backgroundColor: teal[800], padding: 16,
    '& .main': {fontSize: 24},
    '& .sub': {fontSize: 12},
    '@media (max-width:450px)': {
      fontSize: '1.25rem'
    }
  })
  return(
    <Title>
      <div className="main">あるふぁみ</div>
      <div className="sub">Albatross for family.</div>
    </Title>
  )
}

const Footer = ({contactDt}) => {
  const etc = contactDt?contactDt.etc ?contactDt.etc :{} :{};
  const {hname, bname, pname, tel} = etc;
  const FooterInfo = styled("div")({
    backgroundColor: "#fff3e0", padding: 8,
    '& a': {textDecoration: "none", marginLeft: 4},
    '& .inner': {
      maxWidth: 640, margin: '0 auto',
      '& h2': {fontSize: 18, fontWeight: "600", margin: "16px 0 12px"},
      '& h3': {fontSize: 16, fontWeight: "600", margin: "16px 0 12px"},
      '& .item': {
        fontSize: 14, marginBottom: 8, lineHeight: '1.5rem', minHeight: '1.5rem',
        '& .sama': {marginLeft: 4}
      },
      '& .text': {fontSize: 12}
    }
  })
  return(
    <FooterInfo>
      <div className="inner">
        <h3>事業所情報</h3>
        <div className="item">{hname}</div>
        <div className="item">{bname}</div>
        <div className="item">
          TEL:<a href={`TEL:${tel}`}>{tel}</a>
        </div>
        <h3>保護者様お名前</h3>
        <div className="item">{pname}<span className="sama">様</span></div>
        <div className="text">
          <p>ご不明点がございましたら事業所様にお問い合わせ下さい。</p>
        </div>
      </div>
    </FooterInfo>
  )
}

const checkUrlError = (urlParams) => {
  if(Object.keys(urlParams).length !== 7) return false;
  const valueErr =  Object.keys(urlParams).every(key => {
    let regExp = "";
    switch(key){
      case "jino":
        regExp = "^[0-9]{10}$";
        break
      case "uid": 
        regExp = "^[0-9]+?$";
        break
      case "token":
        regExp = "^[a-zA-Z0-9]{8}$";
        break
      case "ctoken":
        regExp = "^[a-zA-Z0-9]{16}$";
        break
      case "stdDate":
        regExp = "^[0-9]{4}-(0[1-9]|1[0-2])-01$";
        break
      case "date":
        regExp = "^(0[1-9]|[1-2][0-9]|3[0-1])$";
        break
      case "mail":
        // regExp = "^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$";
        regExp = "";
        break
      default:
        return false;
    }
    const value = urlParams[key];
    return new RegExp(regExp).test(value);
  });
  if(!valueErr) return false;
  const stdDateList = urlParams.stdDate.split("-").map(x => parseInt(x));
  const targetDate = new Date(stdDateList[0], stdDateList[1]-1, parseInt(urlParams.date));
  if(!(stdDateList[0] === targetDate.getFullYear() && stdDateList[1] === targetDate.getMonth()+1)) return false;
  return true;
}

export const FamilyReplyForm = () => {
  const [snack, setSnack] = useState({});
  const locationSearch = useLocation().search.replace("?", "");
  const searchList = locationSearch.split("&");
  const params = searchList.reduce((result, str) => {
    if(!str.includes("=")) return result;
    const [key, val] = str.split("=");
    if(key === "s" && val.includes("-")){
      const keys = ["jino", "uid", "token", "ctoken", "stdDate", "date"];
      val.split("-").forEach((x, i) => {
        if(keys[i] === "stdDate"){
          const year = x.slice(0, 4);
          const month = x.slice(4, 6);
          const tDate = x.slice(6, 8);
          x = `${year}-${month}-${tDate}`;
        }
        result[keys[i]] = x;
      });
    }else{
      result[key] = val;
    }
    return result;
  }, {});
  const {jino, token, ctoken, uid, stdDate, date} = params;
  const albDtParams = {a: "fetchPartOfContactJino", jino, faptaken: token, ctoken, uid, date: stdDate};
  const [contactDt, setContactDt] = useFetchAlbDt({apiParams: albDtParams})

  const FapApp = styled('div')({
    width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    minHeight: '100vh'
  })

  return(
    <FapApp>
      <Header />
      {checkUrlError(params)
        ?<MainForm params={params} setSnack={setSnack} contactDt={contactDt} setContactDt={setContactDt}/>
        :<UrlError />
      }
      <Footer contactDt={contactDt}/>
      <FapSnackMsg snack={snack} setSnack={setSnack}/>
    </FapApp>
  )
}