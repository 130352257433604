import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DoneIcon from '@mui/icons-material/Done';
import styled from '@emotion/styled';
import { blue, red } from '@mui/material/colors';

const SendedData = styled("div")({
  fontSize: '0.8rem',
  display: 'flex', alignItems: 'center',
  '& .draftMsg': {
    display: 'flex', alignItems: 'center',
    '& .MuiSvgIcon-root': {fontSize: 16}
  },
  '& .done': {
    color: blue[800], fontWeight: 'bold'
  },
  '& .notYet': {
    color: red[600]
  },
  '& .timestamp': {
    marginLeft: 4,
    '& .time': {marginLeft: 2}
  }
})

export const SendedJudg = ({timestamp=null, draft, option, style={}}) => {
  const draftNode = (() => {
    if(draft === true){
      return(<><PriorityHighIcon/>下書き</>);
    }else if(draft === false || (option==="preMsg" && timestamp)){
      return(<><DoneIcon/>送信済み</>);
    }else if(option==="familyMsg" && timestamp){
      return(<><DoneIcon/>送信済み</>);
    }else{
      return null;
    }
  })();
  
  let newDate, month, date, hour, minutes, sendMD, sendTime;
  if(timestamp){
    newDate = new Date(timestamp);
    month = String(newDate.getMonth()+1).padStart(2, "0");
    date = String(newDate.getDate()).padStart(2, "0");
    hour = String(newDate.getHours()).padStart(2, "0");
    minutes = String(newDate.getMinutes()).padStart(2, "0");
    sendMD = `${month}/${date}`;
    sendTime = `${hour}:${minutes}`;
  }
  
  return(
    <SendedData style={style}>
      <div className={`draftMsg ${draft ?"notYet" :"done"}`}>{draftNode}</div>
      {timestamp &&<div className={`timestamp ${draft ?"notYet" :"done"}`}>
        <span className='md'>{sendMD}</span>
        <span className='time'>{sendTime}</span>
      </div>}
    </SendedData>
  )
}