import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { FamilyReplyForm } from './components/FamilyReplyForm';
import { LineAuthenticationForm } from './components/LineAuthenticationForm';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path={"/fmassage/"} element={<FamilyReplyForm/>}/>
      </Routes>
    </HashRouter>
    // <BrowserRouter basename='/test/'>
    //   <Routes>
    //     <Route path={"/fmassage/"} element={<FamilyReplyForm />} />
    //     <Route path="/" element={<LineAuthenticationForm />} />
    //   </Routes>
    // </BrowserRouter>
  );
}

// function App() {
//   return (
//     <HashRouter>
//       <Routes>
//         <Route path={"/fmassage/"} element={<FamilyReplyForm/>}/>
//       </Routes>
//     </HashRouter>
//   );
// }

export default App;
