import styled from "@emotion/styled";
import { Alert, Snackbar } from "@mui/material";
import { blue, red, teal } from "@mui/material/colors";
import { useEffect, useState } from "react";

const FapSnackbar = styled(Snackbar)({
  '& .MuiAlert-icon': {
    color: '#fff !important'
  },
  '& .alert': {
    width: '100%', color: '#fff', fontWeight: 'bold'
  }
});

const FapSnackMsg = ({snack, setSnack}) => {
  const {open, msg, severity, id} = snack;
  const [barOpen, setBarOpen] = useState(false);

  useEffect(() => {
    if(open){
      setBarOpen(true);
    }
  }, [open, msg, severity, id]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setBarOpen(false);
    setSnack({})
  }

  const alertColor = (() => {
    if(severity === "success") return blue[700];
    else if(severity === "error") return red[700];
    return null
  })();

  return(
    <FapSnackbar
      open={barOpen} autoHideDuration={12000} onClose={handleClose}
    >
      <Alert
        onClose={handleClose} severity={severity} className="alert"
        sx={{backgroundColor: alertColor}}
      >
        {msg}
      </Alert>
    </FapSnackbar>
  )
}
export default FapSnackMsg;