import { useEffect, useState } from "react"
import axios from 'axios';

// export const endPoint = "https://houday.rbatos.com/api/apixfg.php"
export const endPoint = "https://houday.rbatos.com/api/api.php"

export const makeUrlSearchParams = (params)=>{
  let rt = new URLSearchParams('');
  Object.keys(params).forEach(key=>{
    rt.append(key, params[key]);
  });
  return rt;
}

export const getDataType = (data) => {
  const toString = Object.prototype.toString;
  const type = toString.call(data).slice(8, -1).toLowerCase();
  return type;
}

export const useFetchAlbDt = ({apiParams={}, responsePath="", onErrorReturnValue={}, setSnack}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    let unmounted = false;
    (async() => {
      const res = await axios.post(endPoint, makeUrlSearchParams(apiParams));
      if(!(res && res.data && res.data.result && (res.data.dt || res.data.dt[0]))){
        if(setSnack) setSnack({...{msg: 'データの取得に失敗しました。', severity: 'warning'}});
        setData(onErrorReturnValue)
        return;
      }
      let resDt = res.data.dt[0] ?res.data.dt[0] :res.data.dt;
      let pathList = [];
      if(responsePath){
        const pathDtType = getDataType(responsePath);
        if(pathDtType === 'string') pathList = responsePath.split(".");
        else if(pathDtType === 'array') pathList = [...responsePath];
        resDt = pathList.reduce((result, path) => {
          if(result === null || !result[path]) return null;
          return result[path];
        }, {...resDt});
      }
      const resultRes = resDt ?resDt :onErrorReturnValue;
      if(!unmounted){
        setData(resultRes);
      }
    })();
    return () => { unmounted = true; };
  }, []);

  return [data, setData];
}